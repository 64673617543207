<template>
  <div>
    <div class="auth-wrapper authentcation-auth auth-v1 px-2">
      <div class="auth-inner py-2">
        <b-card class="mb-0 pt-2">
          <b-card-title class="mb-1 text-center pt-2 pb-2">
            {{ $t("auth.sign_up") }}
          </b-card-title>

          <!--   <div class="authentcation pb-2  mb-3"  v-if="tabIndex<2"> -->

          <!--  type="password"
   @change="handleChange" -->
          <template>
            <validation-observer ref="registerRules" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="validationForm">
                <b-tabs
                  class="bg-eef3f4"
                  :nav-class="{
                    'no-title': !setting_auth.verifiy_by_mobile || !setting_auth.email_verification
                  }"
                  v-model="tabIndex"
                  @changed="updateLoginBy"
                >
                  <b-tab
                    v-if="setting_auth.verifiy_by_mobile"
                    :title-item-class="`email_verification_${setting_auth.email_verification}`"
                  >
                    <template v-if="setting_auth.email_verification" #title>
                      <feather-icon icon="PhoneIcon" />
                      <span> برقم الجوال</span>
                    </template>
                    <div class="pt-3">
                      <b-form-group label="رقم الجوال" label-for="phone">
                        <validation-provider #default="{ errors }" name="phone" ref="phoneFieldValidator" :rules="rulesPhone">
                          <!--    <b-form-input
                    id="phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  /> -->
                          <!--  :dropdownOptions="{
      showSearchBox:true,
      required:true,
      placeholder:'رقم التلفون ',

    }"
    customValidate="/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/"    -->

                          <vue-tel-input
                            v-model="formData.phone_number"
                            mode="national"
                            :autoFormat="false"
                            :validCharactersOnly="true"
                            :defaultCountry="defaultCountry"
                            :onlyCountries="countres"
                            :invalidMsg="errors[0]"
                            :dropdownOptions="{
                              disabled: false,
                              tabindex: 0,
                              showDialCodeInSelection: true,
                              showDialCode: true,
                              showFlags: true
                            }"
                            :inputOptions="{
                              autocomplete: 'off',
                              readonly: false,
                              maxlength: 12,
                              name: 'phone',
                              id: 'phone',
                              type: 'tel',
                              required: formData.authentcate_by == 'phone',
                              placeholder: 'رقم التلفون '
                            }"
                            name="phone"
                            @validate="updateFormData"
                            @country-changed="updateFormData"
                            wrapperClasses="phone-wrapper"
                            placeholder="رقم التلفون "
                          >
                            <template #arrow-icon="{ open }">
                              <span class="vti__dropdown-arrow">{{ open ? "▲" : "▼" }}</span>
                            </template>
                          </vue-tel-input>
                          <!--    :dropdownOptions="{
       disabled:false,
       tabindex:0,
       showDialCodeInSelection:true,
       showDialCode:true,
       showFlags:true,
   }" -->

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-tab>
                  <b-tab
                    v-if="setting_auth.email_verification"
                    :title-item-class="`verifiy_by_mobile_${setting_auth.verifiy_by_mobile}`"
                  >
                    <template #title>
                      <feather-icon icon="MailIcon" />
                      <span> بالبريد الإلكتروني</span>
                    </template>
                    <div class="pt-3">
                      <b-form-group label="البريد الإلكتروني" label-for="email">
                        <validation-provider #default="{ errors }" name="email" :rules="rulesEmail">
                          <b-form-input
                            id="email"
                            v-model="formData.email"
                            type="email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="john.doe@email.com"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-tab>
                </b-tabs>

                <b-form-group label="" label-for="checkbox-1">
                  <validation-provider #default="{ errors }" name="checkbox-1" rules="required">
                    <b-form-checkbox id="register-privacy-policy" v-model="formData.agree" name="checkbox-1">
                      بالتسجيل فإنك توافق على
                      <b-link href="/Term-Conditions"> الشروط والأحكام </b-link>
                      الخاصة بنا.
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- <validation-provider
                  v-if="setting_auth.enabled_sms_google && !verify_sms_sa"
                  #default="{ errors }"
                  name="recaptcha"
                  :rules="rulesRecaptcha"
                >
                  <input
                    name="recaptcha"
                    v-model="formData.recaptcha"
                    type="hidden"
                  />
                  <div id="recaptcha-container"></div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->

                <b-button id="sign-in-button" variant="primary" type="submit" block :disabled="invalid || submitProcessing">
                  <b-spinner small type="grow" v-if="submitProcessing"></b-spinner>
                  التالي
                  <feather-icon icon="ChevronLeftIcon" />
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>{{ $t("auth.have_account") }}</span>
              <b-link
                :to="{
                  name: 'auth-login'
                }"
              >
                <span>{{ $t("auth.login") }}</span>
              </b-link>
            </b-card-text>
          </template>
        </b-card>
      </div>
    </div>

    <template v-if="step_number == 1">
      <!-- <verify
        v-if="verify_sms_sa || tabIndex == 1"
        :countres="countres"
        :token="activation_token"
        :formData="formData"
        :type_registration="type_registration"
        :form_type="formData.form_type"
        @updateVerifyForm="updateFormAfterVerfiy"
        @updateTabIndex="(newVale) => (step_number = newVale)"
        @updateRegisterType="(newVale) => (formData.form_type = newVale)"
        @restAll="restAll"
      /> -->

      <!-- <VerifyFirebase
        v-if="
          setting_auth.enabled_sms_google && !verify_sms_sa && tabIndex == 0
        "
        :formData="formData"
        :countres="countres"
        :type_registration="type_registration"
        :appVerifier="recaptchaVerifier"
        :form_type="formData.form_type"
        @updateTabIndex="(newVale) => (step_number = newVale)"
        @updateVerifyForm="updateFormAfterVerfiy"
      /> -->

      <!--  -->
    </template>
    <!-- <template v-if="step_number == 2">
      <FinishedProfile :formData="dataProfile" />
    </template> -->
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
  BTab,
  BTabs
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

//import FinishedProfile from "@/views/auth/parts/FinishedProfile.vue";

import { utilsMixin } from "@core/mixins/AuthForm/index.js";
//import verify from "./verify.vue";
//import VerifyFirebase from "./VerifyFirebase.vue";

import "./style.css";

//import { VueRecaptcha } from "vue-recaptcha";

// import {
//   createRecaptchaVerifier,
//   isCaptchaOK,
//   isExpireToken,
//   sendVerificationCode,
// } from "@/libs/firebase/PhoneAuthHelper.js";

export default {
  name: "register",

  directives: {
    Ripple
  },
  components: {
    VueTelInput,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BTab,
    BTabs,

    BForm,
    BFormSelect,
    BButton,
    BFormInvalidFeedback,
    BLink,
    BSpinner,
    BFormCheckbox,
    BCard,
    BCardTitle,
    BCardText,

    BInputGroup,
    BInputGroupAppend,

    ValidationProvider,
    ValidationObserver

    // FinishedProfile,
    // verify,
    //  VerifyFirebase,
    //  VueRecaptcha,
  },
  data() {
    return {
      current_component: "register",
      type_registration: "temp",
      recaptchaVerifier: true,
      countryCode: "",

      formData: {
        //  phone_number:"",
        authentcate_by: "phone",
        recaptcha: undefined,
        form_type: "register",
        agree: false,
        phone: "",
        phone_number: "",
        email: ""
      },

      dataProfile: {}
    };
  },
  mixins: [utilsMixin],
  computed: {
    loginByVeryfiy() {
      return this.formData.authentcate_by === "phone";
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    rulesEmail() {
      return this.formData.authentcate_by === "email" ? "required|email" : undefined;
    },
    rulesPhone() {
      return this.formData.authentcate_by === "phone" ? "required|phone_validate" : undefined;
    }

    // rulesRecaptcha() {
    //   this.formData.recaptcha = this.recaptchaData;
    //   return !isCaptchaOK() ? "required" : undefined;
    // },
  },
  mounted() {
    if (this.setting_auth.verifiy_by_mobile == true) {
      this.formData.authentcate_by = "phone";
    } else {
      this.formData.authentcate_by = "email";
    }
    const self = this;
    // if (setting_auth.enabled_sms_google) {
    //   //        setTimeout(() => {
    //   // generateRecaptcha();
    //   //  }, 1200);
    // //  this.regenrateCaptcha();
    // }

    // this.$refs.loginForm.validate();
  },
  methods: {
    regenrateCaptcha() {
      try {
        const recaptchaVerifier = createRecaptchaVerifier();
        // Handle success
        this.recaptchaVerifier = recaptchaVerifier;
      } catch (error) {
        // Handle error
        this.showToast(error.message, "danger");
        console.error("Error while checking the verification code", error);
      }
    },

    verifyMethod(response) {
      this.captchaVerify = true;
      this.formData.recaptcha = response;
      //console.log('verifyMethod',response)
    },
    expiredMethod(response) {
      console.log("expiredMethod", response);
    },
    renderMethod(response) {
      console.log("renderMethod", response);
    },
    errorMethod() {
      var error = [{ captcha: "captcha" }];
      this.$refs.loginForm.setErrors(error);
      //
    },
    updateLoginBy() {
      // console.log('data',data)
      if (this.setting_auth.verifiy_by_mobile == true && this.tabIndex == 0) {
        this.formData.authentcate_by = "phone";
      } else {
        this.formData.authentcate_by = "email";
      }
      this.validationForm();
    },
    async sendToFireBase(phone, msg) {
      // let formData=this.formData;
      //  formData=this.getFormData(formData);
      try {
        const verificationId = await sendVerificationCode(phone, this.recaptchaVerifier);
        // Handle success
        this.submitProcessing = false;
        this.step_number = 1;
        //    this.confirmationResult = verificationId;
        this.recaptchaVerifier = verificationId;
        this.showToast(msg, "success");
      } catch (error) {
        this.submitProcessing = false;
        // if (isExpireToken(error.code)) {
        //   this.regenrateCaptcha();
        // }
        if (error.code == "auth/too-many-requests") {
          this.showToast(
            "لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.",
            "danger"
          );
        } else {
          this.showToast(error.message, "danger");
        }
        console.error("Error while checking the verification code", error);
      }

      //  sendOtpForVerification({
      // phoneNumber:phone,
      //  success: (res) => {
      //         this.submitProcessing = false
      //        this.step_number=1;
      //         this.confirmationResult = res;
      //          this.showToast(msg, 'success')
      //       },
      //         error: (error) => {
      //           this.submitProcessing = false

      //             if(error.code=="auth/too-many-requests"){
      //                  this.showToast("لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.", 'danger')

      //             }else{
      //                       this.showToast(error.message, 'danger')
      //             }
      //           console.error('Error while checking the verification code', error);

      //   }

      //   });
    },
    detectEnablePath() {
      let formData = this.formData;

      if (this.formData.authentcate_by == "phone") {
        formData = this.getFormData(formData);
      }
      this.submitProcessing = true;
      this.register(formData);
      return true;
    },

    register() {
      this.submitProcessing = true;
      let formData = this.formData;

      if (formData.authentcate_by == "phone") {
        formData = this.getFormData(formData);
      }

      // console.log('formData',formData);
      //formData.authentcate_by=this.tabIndex==0?'phone':'email';

      this.$http
        .post(`${this.prefixApi}/register`, formData)
        .then(response => {
          let data = response.data;
          let message = data.msg ? data.msg : data.message;
          this.submitProcessing = false;
          if (response.data.status === true) {
            if (data.token) {
              this.activation_token = data.token;
            }

            this.step_number = 1;
            this.showToast(message, "success");

            if (data.url) {
              this.submitProcessing = true;
              //   window.location.href = data.url;
            }
            var newData = {
              ...formData,
              ...data
            };
            if (data.type) {
              this.type_registration = data.type;
              newData.type_registration = data.type;
            }
            this.$store.dispatch("setDataAuth", newData);

            this.$router.push({
              name: "auth-verify",
              params: { verfiy_token: this.activation_token }
            });
            //  this.$router.push({ name: 'auth-login' })
          } else {
            this.showToast(message, "danger");
            // this.$router.push({ name: 'misc-error-404' })
          }
        })
        .catch(error => {
          this.submitProcessing = false;
          let result = error.response;
          if (error.response && error.response.data) {
            result = error.response.data;
          }
          if (result.step_to_register) {
            this.restAll(this.form_type);
          }
          this.errorsHandler(error, "registerRules");
        });
    },
    updateFormAfterVerfiy(data) {
      this.dataProfile = {
        ...this.formData,
        ...data
      };
      this.step_number = 2;
    },
    validationForm() {
      let isValidPhone = this.isValidPhone;

      if (!this.isValidPhone) {
        const errors = ["يجب ادخال رقم الجوال بشكل صحيح"];
        this.$refs.phoneFieldValidator.setErrors(errors);
        this.showToast("يجب ادخال رقم الجوال بشكل صحيح", "danger");
        return false;
      }

      this.$refs.registerRules.validate().then(success => {
        if (success) {
          this.detectEnablePath();
        } else {
          this.showToast("يجب التحقق من الحقول", "danger");
        }
      });
    }
  }
};
</script>

<style lang="scss"></style>
